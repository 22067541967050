import { AbstractControl } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { IListDataOCRXls } from 'src/app/pages/microsite/components/microsite-upload/microsite-upload.component';
import { SharedService } from 'src/app/services/shared.service';
import { AppConstants } from 'src/app/types/constants/app.constant';
import { ENDPOINT } from 'src/app/types/constants/endpoint.constant';
import { DocLicenseDocTypes } from 'src/app/types/enums/document-license.enum';
import { MediaGroup, MediaMandatory, MediaOptional } from 'src/app/types/enums/media.enum';
import { MicrositeStatus } from 'src/app/types/enums/mikrosite.enum';
import { BAHistoriesStatus, StatusTypes, UploadStatus } from 'src/app/types/enums/other.enum';
import { AccordionDetail, CardData, IApiResponseDetail, IBAHistory, IInspectionDetail, IMediaInfo } from 'src/app/types/interfaces/common.interface';
import { IDocument } from 'src/app/types/interfaces/document-license.interface';
import { IMikrositeDetail } from 'src/app/types/interfaces/mikrosite.interface';
import { IDetailFieldConfig } from '../components/detail-list/detail-list.component';
import { ToastService } from '../components/toast/toast.service';

export const snakeToSentenceCase = (str: string): string => {
  const words = str.split('_');
  const sentenceCaseWords = words.map((word) => {
    const lowerCaseWord = word.toLowerCase();
    return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
  });
  return sentenceCaseWords.join(' ');
};
export const textSpaceToSentenceCaseSpace = (str: string): string => {
  const words = str.split(' ');
  const sentenceCaseWords = words.map((word) => {
    const lowerCaseWord = word.toLowerCase();
    return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
  });
  return sentenceCaseWords.join(' ');
};
export const textToUpperCase = (text: string)=> {
  if (text) return text.toUpperCase();
  return text;
}

export const convertDate = (date: Date|string|number)=> {
  if (!date) return date; // in case date is null or undefined or empty string
  const converted = moment(date, AppConstants.EXCEL_DATE_FORMAT)
  if (converted.isValid()) return converted.format('YYYY-MM-DD');
  return date;
}

export const getDateRange = (selectedYear: string, selectedMonth: string) => {
  if (+selectedMonth === 12) {
    // If selectedMonth is 0, set startDate to 1st January and endDate to 31st December
    const startDate = convertDate(new Date(+selectedYear, 0, 1));
    const endDate = convertDate(new Date(+selectedYear, 11, 31));
    return { startDate, endDate };
  } else {
    // Otherwise, calculate startDate and endDate based on the selected month
    const startDate = convertDate(new Date(+selectedYear, +selectedMonth, 1));
    const endDate = convertDate(new Date(+selectedYear, +selectedMonth + 1, 0));
    return { startDate, endDate };
  }
} // Month sould be incremented because BE is counting month start from 1, while javascript start from 0

export const sort = (data: any, sort: string)=> {
  return data.sort((a: any, b: any) => {
    if (a[sort] < b[sort]) {
      return -1;
    }
    if (a[sort] > b[sort]) {
      return 1;
    }
    return 0;
  });
}

export const displayResponseErrorIfAny = <T>(err: IApiResponseDetail<T>, toastService: ToastService): boolean => {
  if (err?.response_schema?.response_message) {
    toastService.error(err.response_schema.response_message.english);
    return true;
  }
  return false;
}

export const isEmptyString = (str: string) => {
  return str == null || str == undefined || str === "";
}

export const getNestedProperty = (obj: any, path: string): any => {
  const properties = path.split('.');
  let currentObject = obj;

  for (const property of properties) {
    if (currentObject && currentObject.hasOwnProperty(property)) {
      currentObject = currentObject[property];
    } else {
      return undefined; // Property not found
    }
  }

  return currentObject;
}

export const trackByFn = (index: number, item: any): any => {
  return item.someUniqueIdentifier; // Replace with a unique identifier from your item
}

export const createAccordionList = (name: string, fields?: IDetailFieldConfig[], cardsList?: CardData[]): AccordionDetail => {
  return { name, fields, cardsList };
}

export const createMicrositeDetailDocDataFieldConfig = (inspectionDetail: IInspectionDetail) => {
  let mappedData: CardData[] = Object.values(DocLicenseDocTypes)
    .map((docType) => {
    const document = inspectionDetail.documents?.find(
      (document) => document.document_type.document_type === docType
    );
    const expiry_status = document?.expiry_status
    let titleStatus;
    if (document) {
        titleStatus = expiry_status === 'VALID' ? '' : expiry_status;
    } else {
        titleStatus = UploadStatus.NOT_UPLOADED;
    }
    return {
      id: docType,
      title: `DOCUMENT_LICENSE.LABEL.${docType}`,
      titleStatus: titleStatus,
      titleStatusType: StatusTypes.UPLOAD_STATUS,
      fields: getFieldsConfigByDocType(docType, document),
      value: {
        microsite_id: inspectionDetail.microsite?.id,
        type: docType
      }
    }
  });

  return createAccordionList('MICROSITE_DETAIL.DOCUMENT_DATA', undefined, mappedData);
}

export const addSeeHistoryButtons = (accordionData: AccordionDetail)=> {
  accordionData.cardsList?.forEach(i => {
      i.customFooterId = 'seeHistoryFooter';
    });
  return accordionData;
}

export const getFieldsConfigByDocType = (docType: DocLicenseDocTypes, document?: IDocument) => {
  const fields:IDetailFieldConfig[] = [];
  const fDocDate = {
    label: 'DOCUMENT_LICENSE.LABEL.DOCUMENT_DATE',
    type: 'date',
    value: document?.document_date,
  };
  const fLocPermitIssuer = {
    label: 'DOCUMENT_LICENSE.LABEL.LOCATION_PERMIT_ISSUER',
    value: document?.issuer,
  };
  const fSiteAddress = {
    label: 'DOCUMENT_LICENSE.LABEL.SITE_ADDRESS',
    value: document?.site_address,
  };
  const fSiteId = {
    label: 'DOCUMENT_LICENSE.LABEL.SITE_ID',
    value: document?.site_id,
  };
  const fSiteName = {
    label: 'DOCUMENT_LICENSE.LABEL.SITE_NAME',
    value: document?.site_name,
  };
  const fEnvPermitIssuer = {
    label: 'DOCUMENT_LICENSE.LABEL.ENVIRONMENT_PERMIT_ISSUER',
    value: document?.issuer,
  };
  const fSKHPIssuer = {
    label: 'DOCUMENT_LICENSE.LABEL.SKHP_ISSUER',
    value: document?.issuer,
  };
  const fDocValidity = {
    label: 'DOCUMENT_LICENSE.LABEL.DOCUMENT_VALIDITY',
    type: 'date',
    value: document?.document_validity,
  };
  const fBaTeraIssuer = {
    label: 'DOCUMENT_LICENSE.LABEL.BA_TERA_ISSUER',
    value: document?.issuer,
  };
  const fBaTeraDate = {
    label: 'DOCUMENT_LICENSE.LABEL.BA_TERA_DATE',
    type: 'date',
    value: document?.document_date,
  };
  const fContractorName = {
    label: 'DOCUMENT_LICENSE.LABEL.CONTRACTOR_NAME',
    value: document?.issuer,
  };
  const fDrawingNumber = {
    label: 'DOCUMENT_LICENSE.LABEL.DRAWING_NUMBER',
    value: document?.document_number,
  };

  if (docType !== DocLicenseDocTypes.AS_BUILT_DRAWING) fields.push({
    label: 'DOCUMENT_LICENSE.LABEL.DOCUMENT_NUMBER',
    value: document?.document_number,
  });
  switch(docType) {
    case DocLicenseDocTypes.LOCATION_PERMIT: fields.push(
        fDocDate,
        fLocPermitIssuer,
        fSiteAddress,
      ); break;
    case DocLicenseDocTypes.ENVIRONMENT_PERMIT: fields.push(
        fEnvPermitIssuer,
        fSiteId,
        fSiteName,
        fSiteAddress,
        fDocDate,
      ); break;
    case DocLicenseDocTypes.SKHP: fields.push(
        fSKHPIssuer,
        fDocDate,
        fDocValidity,
      ); break;
    case DocLicenseDocTypes.BA_TERA: fields.push(
        fBaTeraIssuer,
        fBaTeraDate,
      ); break;
    case DocLicenseDocTypes.AS_BUILT_DRAWING: fields.push(
        fContractorName,
        fDrawingNumber,
        fSiteAddress,
      ); break;
    case DocLicenseDocTypes.COMMISSIONING_REPORT: fields.push(
        fSiteId,
        fSiteName,
        fDocDate,
      ); break;

  }
  fields.push(
    {
      label: 'DOCUMENT_LICENSE.LABEL.DOCUMENT_FILE',
      customElementId: 'fieldButton',
      value: document?.file_upload,
    },
    {
      label: 'DOCUMENT_LICENSE.LABEL.UPLOAD_DATE',
      type: 'date',
      value: document?.file_upload?.created_at,
    }
  );
  return fields;
}

export const createMicrositeOwnershipFieldConfig = (microsite: IMikrositeDetail, isForDocLicenseDetail:boolean = false) => {
  return [
    {
      label: 'MICROSITE_DETAIL.SITE_ID',
      value: microsite?.site_info.site_id,
    },
    {
      label: 'MICROSITE_DETAIL.SITE_NAME',
      value: microsite?.site_info.site_name,
    },
    ...(isForDocLicenseDetail? [] : [
      {
        label: 'MICROSITE_DETAIL.OWNERSHIP',
        value: microsite?.site_info.ownership,
      }
    ]),
    {
      label: 'MICROSITE_DETAIL.SITE_ADDRESS',
      value: microsite?.site_info.site_address,
    },
    ...(isForDocLicenseDetail? [
      {
        label: 'COMMON.DOCUMENT_STATUS',
        value: microsite?.status.document_status,
        customElementId: 'documentStatus'
      },
      {
        label: 'COMMON.SITE_READY_TO_INSPECT',
        type: 'date',
        value: microsite?.site_ready_to_inspect_date,
      },
    ] : [
      {
        label: 'MICROSITE_DETAIL.SUB_DISTRICT',
        value: microsite?.site_info?.subdistrict?.subdistrict_name,
      },
      {
        label: 'MICROSITE_DETAIL.DISTRICT',
        value: microsite?.site_info?.subdistrict?.district_name,
      },
      {
        label: 'MICROSITE_DETAIL.PROVINCE',
        value: microsite?.site_info?.subdistrict?.province_name,
      },
      {
        label: 'MICROSITE_DETAIL.PIN_POINT_LOCATION',
        customElementId: 'locationMapButton',
        value: {
          latitude: microsite?.site_info.coordinate_lat,
          longitude: microsite?.site_info.coordinate_long,
        }
      },
      {
        label: 'MICROSITE_DETAIL.COORDINATE',
        value:
          (
            microsite?.site_info?.coordinate_lat != null &&
            microsite?.site_info?.coordinate_long != null
          )?
          (
            microsite?.site_info.coordinate_lat +
            ',' +
            microsite?.site_info.coordinate_long
          ) :
          '',
      },
    ]),
    {
      label: 'COMMON.OPERATOR_PHONE_NO',
      value: microsite?.site_info?.operator_phone_no
    },
    {
      label: 'COMMON.OPERATOR_NAME',
      value: microsite?.site_info?.operator_name
    },
    ...(isForDocLicenseDetail? [
      createOperationStatusConfig(microsite)
    ] : [])

  ];
}

export const createOperationStatusConfig = (microsite: IMikrositeDetail): IDetailFieldConfig => {
  return {
    label: 'MICROSITE_DETAIL.OPERATION_STATUS',
    value: microsite?.status?.operation_status ? `OPERATION_STATUS.${microsite?.status.operation_status}` : '',
    customPipe: {
      pipeType: TranslatePipe,
      pipeArgs: []
    }
  };
}

export const createSiteDataFieldConfig = (inspectionData: IInspectionDetail, forInspectionCompany:boolean = false): IDetailFieldConfig[] => {
  const { microsite, inspection_info } = inspectionData;
  return [
    ...(forInspectionCompany? [
      {
        label: 'COMMON.SITE_READY_TO_INSPECT',
        value: microsite?.site_ready_to_inspect_date,
        type: 'date',
      },
      {
        label: 'COMMON.INSPECTION_DATE',
        value: inspection_info?.inspection_date,
        type: 'date',
      },
    ] : [
      {
        label: 'COMMON.SITE_OPERATIONAL_DATE',
        value: microsite?.site_info.operational_date,
        type: 'date',
      },
    ]),
    createOperationStatusConfig(microsite),
    {
      label: 'MICROSITE_DETAIL.CAPACITY_DATA',
      type: 'number',
      value: microsite?.product_info?.capacity_data,
    },
    {
      label: 'MICROSITE_DETAIL.FUELS_TYPE',
      type: 'list',
      value: microsite?.fuel_types as any,
    },
    {
      label: 'MICROSITE_DETAIL.PRODUCT_TYPE',
      type: 'list',
      value: microsite?.product_types as any,
    },
    {
      label: 'MICROSITE_DETAIL.TERMINAL_SUPPLY',
      value:
        microsite?.product_info?.terminal_supply?.terminal_supply_name,
    },
    {
      label: 'MICROSITE_DETAIL.DISTRIBUTION_AREA',
      value: microsite?.product_info?.distribution_area?.district_name,
    },
  ];
}

export const addMandatoryMediaPlaceholders = (data: IInspectionDetail) => {
  data.mandatory = Object.values(MediaMandatory).map(v => {
    const match = data.mandatory.find(i => i.title === v);
    return {
      ...(match? match : {}),
      media_group: MediaGroup.MANDATORY,
      title: v,
    } as IMediaInfo;
  });
  return data;
}

export const addOptionalMediaPlaceholders = (data: IInspectionDetail) => {
  data.optional = Object.values(MediaOptional).map(v => {
    const match = data.optional.find(i => i.title === v);
    return {
      ...(match? match : {}),
      media_group: MediaGroup.OPTIONAL,
      title: v,
    } as IMediaInfo;
  });
  return data;
}

export const addVideoMediaPlaceholder = (data: IInspectionDetail) => {
  if (data.video == null || data.video.length === 0) {
    data.video = [
      {
        media_group: MediaGroup.VIDEO,
      } as IMediaInfo
    ];
  }
  return data;
}

export const addOtherMediaPlaceholders = (data: IInspectionDetail) => {
  data.other ??= [];
  if (data.other.length === 0) {
    data.other = [
      {
        media_group: MediaGroup.OTHER,
      } as IMediaInfo
    ];
  }
  return data;
}

export const getImageUrl = (media: IMediaInfo): string => {
  return media.file_upload_id?.id ? ENDPOINT.FILE_DOWNLOAD(media.file_upload_id.id) :
        media.file_upload_id?.file_path ?? '';
}

export const getThumbnailUrl = (media: IMediaInfo): string => {
  return media.file_upload_id?.id ? ENDPOINT.IMG_THUMBNAIL(media.file_upload_id.id) :
        media.file_upload_id?.file_path ?? '';
}

export const invalidField = (formControl: AbstractControl) => {
  return formControl.touched && formControl.invalid;
}

export const translateWithGroupPrefix = (translateService: TranslateService, key: string, prefix: string):string => {
  if (isEmptyString(key)) return key;
  const fullKey = `${prefix}.${key}`;
  let s = translateService.instant(fullKey);
  return (fullKey === s? key : s);
}

export const getErrorMessage = (err: any, translateService:TranslateService) => {
  if (!err || !translateService) return '';
  const lang = translateService.currentLang === 'en'? 'english' : 'indonesian';
  return err.error?.response_schema.response_message[lang];
}

export const getSuccessMessage = (res: any, translateService:TranslateService) => {
  if (!res || !translateService) return '';
  const lang = translateService.currentLang === 'en'? 'english' : 'indonesian';
  return res?.response_schema.response_message[lang];
}

export const isDocLicenseEditable = (m: IMikrositeDetail): boolean => {
  return [
    MicrositeStatus.AWAIT_DOC_UPLOAD,
    MicrositeStatus.COMPLETE_ESSENTIAL_DOC,
    MicrositeStatus.ASSIGNED_INSPECTION,
    MicrositeStatus.NEED_DOCUMENT_REVISION,
    MicrositeStatus.INSPECTION_PROCESS,
    MicrositeStatus.NEED_DATA_REVISION,
    MicrositeStatus.BA_INSPECTION,
    MicrositeStatus.COI_PROCESS,
    MicrositeStatus.COI_ISSUED,
  ].includes(m?.status?.microsite_status as MicrositeStatus);
}

export const fieldsCommonFileUpload: IDetailFieldConfig[] = [
  {
    label: 'COMMON.UPLOAD_DATE',
    type: 'date',
    property: 'upload_time'
  },
  {
    label: 'COMMON.DOCUMENT_FILE',
    property: 'file_upload',
    customElementId: 'fieldButton',
  }
];

export const commonDownloadFile = (sharedService: SharedService, file_id: string) => {
  sharedService.downloadFile(file_id).subscribe({
    error: (err) => {
      console.error(err.message);
    },
  });
}

export const mapRowsArrayToObjectArrayXLSOCR = (content: IListDataOCRXls[]) => {
  return content.map((rowData:any) => rowData.row_data.reduce((acc: any, child: any) => {
    acc[child.name] = {
      value: child.value,
      invalid: !child.is_valid,
      warning: child.error,
    };
    return acc;
  }, {}));
}

export const generateDocumentTracing = (inspectionData: IInspectionDetail, type: 'safety_'|'')=> {
  const idBa = `ba_${type}file` as keyof IInspectionDetail;
  const idBaHistory = `${idBa}_histories` as keyof IInspectionDetail;
  const idBaDistSign = `ba_dist_sign_${type}file` as keyof IInspectionDetail;
  const idBaEmliSign = `ba_emli_sign_${type}file` as keyof IInspectionDetail;
  const arr = [
    {
      id: 'upload_time',
      customId: 'send_by_inspector',
      passed: Boolean(inspectionData[idBa]?.upload_time),
      type: 'date',
      title: 'DOCUMENT_TRACKING.SEND_DOCUMENT',
      data: {
        ...inspectionData[idBa],
        history: inspectionData[idBaHistory]?.filter((item:IBAHistory)=>item.status===BAHistoriesStatus.INSPECTION_UPLOAD)
      }
    },
    {
      id: 'download_time',
      customId: 'receive_by_distributor',
      passed: Boolean(inspectionData[idBa]?.download_time),
      type: 'date',
      title: 'DOCUMENT_TRACKING.RECEIVE_BY_DISTRIBUTOR',
      data: {
        ...inspectionData[idBa],
        history: inspectionData[idBaHistory]?.filter((item:IBAHistory)=>item.status===BAHistoriesStatus.DISTRIBUTOR_DOWNLOAD)
      }
    },
    {
      id: 'upload_time',
      customId: 'send_signed_by_distributor',
      type: 'date',
      passed: Boolean(inspectionData[idBaDistSign]?.upload_time),
      title: 'DOCUMENT_TRACKING.SIGNED_BY_DISTRIBUTOR',
      data: {
        ...inspectionData[idBaDistSign],
        history: inspectionData[idBaHistory]?.filter((item:IBAHistory)=>item.status===BAHistoriesStatus.DISTRIBUTOR_UPLOAD)
      }
    },
    {
      id: 'download_time',
      customId: 'receive_by_emli',
      passed: Boolean(inspectionData[idBaDistSign]?.download_time),
      type: 'date',
      title: 'DOCUMENT_TRACKING.RECEIVE_BY_EMLI',
      data: {
        ...inspectionData[idBaDistSign],
        history: inspectionData[idBaHistory]?.filter((item:IBAHistory)=>item.status===BAHistoriesStatus.EMLI_DOWNLOAD)
      }
    },
    {
      id: 'upload_time',
      customId: 'send_signed_by_emli',
      passed: Boolean(inspectionData[idBaEmliSign]?.upload_time),
      type: 'date',
      title: 'DOCUMENT_TRACKING.SIGNED_BY_EMLI',
      data: {
        ...inspectionData[idBaEmliSign],
        history: inspectionData[idBaHistory]?.filter((item:IBAHistory)=>item.status===BAHistoriesStatus.EMLI_UPLOAD)
      }
    },
  ];
  const lastCheckPoint = arr.filter((item)=>item.passed).pop();
  return {allTrace: arr, lastCheckPoint};
}

export function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}
