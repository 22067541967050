export enum MicrositeStatus {
  AWAIT_DOC_UPLOAD = 'AWAIT_DOC_UPLOAD',
  ASSIGNED_INSPECTION = 'ASSIGNED_INSPECTION',
  INSPECTION_PROCESS = 'INSPECTION_PROCESS',
  COMPLETE_ESSENTIAL_DOC = 'COMPLETE_ESSENTIAL_DOC',
  NEED_DATA_REVISION = 'NEED_DATA_REVISION',
  NEED_DOCUMENT_REVISION = 'NEED_DOCUMENT_REVISION',
  BA_INSPECTION = 'BA_INSPECTION',
  COI_PROCESS = 'COI_PROCESS',
  COI_ISSUED = 'COI_ISSUED',
}

export enum DataStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ExpiryStatus {
  EXPIRED = 'EXPIRED',
  EXPIRED_SOON = 'EXPIRED_SOON',
  VALID = 'VALID',
  ACTIVE = 'ACTIVE'
}

export enum InspectionStatus {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED_INSPECTION = 'ASSIGNED_INSPECTION',
  INSPECTION_PROCESS = 'INSPECTION_PROCESS',
  INSPECTION_COMPLETED = 'INSPECTION_COMPLETED',
  REVISION_REQUIRED = 'REVISION_REQUIRED'
}

export enum OperationStatus {
  CIVIL_WORK = 'CIVIL_WORK',
  READY_FOR_DEPLOYMENT = 'READY_FOR_DEPLOYMENT',
  UNIT_DEPLOYED = 'UNIT_DEPLOYED',
  TERA_FINISHED = 'TERA_FINISHED',
  READY_TO_INSPECT = 'READY_TO_INSPECT',
  OPERATIONAL = 'OPERATIONAL',
  HOLD = 'HOLD',
  DROP = 'DROP',
  CLOSED = 'CLOSED',
}

export enum COIStatus {
  NOT_COMPLETE = 'NOT_COMPLETE',
  COMPLETE = 'COMPLETE',
  EXPIRED = 'EXPIRED',
  EXPIRED_SOON = 'EXPIRED_SOON',
}

export enum MicrositeTabs {
  SUBMITTED = 'SUBMITTED',
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  ELIGIBLE_MAP = 'ELIGIBLE_MAP',
}

export enum DocumentStatus {
  COMPLETE = 'COMPLETE',
  NOT_COMPLETE = 'NOT_COMPLETE'
}

export enum BaDocumentStatus {
  UPLOADED = 'UPLOADED',
  NOT_UPLOADED = 'NOT_UPLOAD'
}

