import { environment } from 'src/environments/environment';
import { DGMigasReportingTabs } from '../enums/dg-migas.enum';

const api: string = environment.REST_API;
const mockApi: string = 'http://localhost:8083/';

export const ENDPOINT = {
  ROLES: api + 'roles',
  ROLES_ACCESS: api + 'roles/access',
  SYNC: api + 'roles/sync',
  GEOLOCATION_PROVINCE: api + 'geolocation/provinces',
  GEOLOCATION_DISTRICTS: api + 'geolocation/districts',
  GEOLOCATION_SUBDISTRICT: api + 'geolocation/subdistricts',
  TERMINAL_SUPPLY: api + 'terminalsupply',
  MICROSITE: api + 'microsite',
  MICROSITE_UPLOAD: api + 'microsite/upload',
  MICROSITE_UPLOAD_TEMPLATE: api + 'microsite/upload/template',
  MICROSITE_UPLOAD_SAVE: api + 'microsite/upload/save',
  MICROSITE_DATA_TABLE_DOWNLOAD: api + 'microsite/data-table/download',
  FUELS_TYPE: api + 'fuel-type',
  FILE_UPLOAD: api + 'file-upload',
  FILE_DOWNLOAD: (fileId: number) => { return `${api}file-upload/${fileId}/download`; },
  IMG_THUMBNAIL: (fileId: number) => { return `${api}file-upload/${fileId}/thumbnail`; },
  PRODUCTS_TYPE: api + 'product-type',
  PROFILE: api + 'profile',
  DOCUMENT_LICENSE: api + 'document-license',
  DOCUMENT_LICENSE_MICROSITE: api + 'document-license/microsite',
  DOCUMENT_LICENSE_MICROSITE_DATA_TABLE_DOWNLOAD: api + 'document-license/microsite/data-table/download',
  DOCUMENT_LICENSE_DOWNLOAD: api + 'document-license/download',
  DOCUMENT_LICENSE_OCR: api + 'document-license/ocr',
  DOCUMENT_LICENSE_VALIDATE: api + 'document-license/validate',
  COI_RENEWAL: api + 'coi-renewal',
  COI_RENEWAL_TABLE_DOWNLOAD: api + 'coi-renewal/data-table/download',
  INSPECTION_COMPANY: api + 'inspection',
  INSPECTION_CHECK_FUEL_ID: (inspectionId: string, fuelUnitId: string) => {
    return `${api}inspection/${inspectionId}/fuel-unit/${fuelUnitId}/duplicate-check`;
  },
  INSPECTION_COMPANY_TABLE_DOWNLOAD: api + 'inspection/data-table/download',
  INSPECTION_COI: api + 'inspection-coi',
  INSPECTION_COI_DOWNLOAD_COI: api + 'inspection-coi/download-coi',
  INSPECTION_COI_TABLE_DOWNLOAD: api + 'inspection-coi/data-table/download',
  INSPECTION_COI_HISTORY: (inspectionId: string) => {
    return `${api}inspection-coi/${inspectionId}/history`;
  },
  INSPECTION_COMPANIES: api + 'inspection-companies',
  PREPARE_INSPECTION_BOOK: api + 'prepare-inspection-book/microsite',
  PREPARE_INSPECTION_BOOK_DOWNLOAD: api + 'prepare-inspection-book/microsite/data-table/download',
  PREPARE_INSPECTION_BOOK_SAVE_UPLOAD: (micrositeId: string) => {
    return `${api}prepare-inspection-book/microsite/${micrositeId}/document`;
  },
  PREPARE_INSPECTION_BOOK_SEND_TO_MIGAS: (micrositeId: string) => {
    return `${api}prepare-inspection-book/microsite/${micrositeId}/send-to-migas`;
  },
  PREPARE_INSPECTION_BOOK_DOWNLOAD_ALL_DOC: (micrositeId: string) => {
    return `${api}prepare-inspection-book/microsite/${micrositeId}/document/download`;
  },
  ASSIGNATIONS: api + 'assignations',
  SIGN_BA_INSPECTION_DISTRIBUTOR: api + 'sign-ba-inspection-dist',
  SIGN_BA_INSPECTION_EMLI: api + 'sign-ba-inspection-emli',
  TERMINAL_PRODUCT: api + 'terminal-product/terminal',
  TERMINAL_RECEIVE: api + 'terminal-receive',
  TERMINAL_RECEIVE_DETAIL: api + 'terminal-receive/detail',
  TERMINAL_RECEIVE_DETAIL_SUMMARY: api + 'terminal-receive/detail-summary',
  TERMINAL_RECEIVE_UPLOAD: api + 'terminal-receive/upload',
  TERMINAL_RECEIVE_UPLOAD_TEMPLATE: api + 'terminal-receive/upload/template',
  TERMINAL_RECEIVE_UPLOAD_SAVE: api + 'terminal-receive/upload/save',
  TERMINAL_TANK: api + 'terminal-tank',
  TERMINAL_LIFTING: api + 'terminal-lifting',
  TERMINAL_LIFTING_RANGE: api + 'terminal-lifting/range',
  TERMINAL_LIFTING_RANGE_SUMMARY: api + 'terminal-lifting/range-summary',
  TERMINAL_LIFTING_UPLOAD: api + 'terminal-lifting/upload',
  TERMINAL_LIFTING_UPLOAD_TEMPLATE: api + 'terminal-lifting/upload/template',
  TERMINAL_LIFTING_UPLOAD_SAVE: api + 'terminal-lifting/upload/save',
  NOTIFICATIONS: api + 'notifications',
  DATA_MONITORING: api + 'data-monitoring/grouped',
  DATA_MONITORING_DETAIL: api + 'data-monitoring/grouped/detail',
  DATA_MONITORING_SUMMARY: api + 'data-monitoring/grouped/detail-summary',
  DATA_MONITORING_TABLE_DOWNLOAD: api + 'data-monitoring/data-table/grouped/download',
  DATA_MONITORING_DETAIL_TABLE_DOWNLOAD: api + 'data-monitoring/data-table/grouped/detail/download',
  MAP_AZURE_SEARCH: 'https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0',
  INSPECTION_DELETE_MEDIA: api + 'inspection/media',
  DG_MIGAS_REPORTING: api + 'dg-migas-reporting',
  DG_MIGAS_REPORTING_TAB: (tab: DGMigasReportingTabs = DGMigasReportingTabs.UNSENT)=> {
    return `${ENDPOINT.DG_MIGAS_REPORTING}/${tab.toLocaleLowerCase()}`
  },
  DG_MIGAS_DOWNLOAD_XLS: (tab: DGMigasReportingTabs)=> {
    return ENDPOINT.DG_MIGAS_REPORTING_TAB(tab) + '/download/report'
  },
  DG_MIGAS_DOWNLOAD_DOC: (tab: DGMigasReportingTabs)=> {
    return ENDPOINT.DG_MIGAS_REPORTING_TAB(tab) + '/download/document'
  },
  LTA_UPLOAD: api + 'assignations/upload',
  LTA_UPLOAD_TEMPLATE: api + 'assignations/upload/template',
  LTA_UPLOAD_SAVE: api + 'assignations/upload/save',
  MONTHLY_STOCK_CALC: api + '',
  DASHBOARD_URL_TYPE: (type: 'dashboard_inspection_tracker'|string)=> {
    return api + `dashboard/url/${type}`
  },
  REPORT_BUILDER: api + 'report-builder',
  REPORT_BUILDER_CSO: api + 'report-builder-cso/grouped',
  REPORT_BUILDER_DOWNLOAD: (id:any)=>{
    return `${ENDPOINT.REPORT_BUILDER}/${id}/download`
  }
}
