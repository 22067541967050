import { Component, Input } from '@angular/core';
import { BaDocumentStatus, COIStatus, DocumentStatus, InspectionStatus, MicrositeStatus, ExpiryStatus } from 'src/app/types/enums/mikrosite.enum';
import { InspectionMediaStatus, StatusTypes, UploadStatus } from 'src/app/types/enums/other.enum';

export type statusType = 'primary' | 'warning' | 'notif' | 'success';

@Component({
  selector: 'app-badge-status',
  templateUrl: './badge-status.component.html',
  styleUrls: ['./badge-status.component.scss']
})
export class BadgeStatusComponent {

  /* Takes precedence over statusType - this value overrides the color from statusType */
  @Input() colorScheme: string;

  @Input() status: string = '';
  @Input() statusType: string;
  @Input() autoContent: boolean = false;

  get badgeColor(): string {
    if (this.colorScheme) return `badge-${this.colorScheme}`;
    switch(this.statusType) {
      case StatusTypes.MEDIA_STATUS:
        switch(this.status)  {
          case InspectionMediaStatus.MAJOR: return 'badge-danger';
          case InspectionMediaStatus.MINOR: return 'badge-yellow';
        }
        break;
      case StatusTypes.UPLOAD_STATUS:
        switch(this.status)  {
          case UploadStatus.UPLOADED: return 'badge-green';
          case UploadStatus.NOT_UPLOADED: return 'badge-danger';
          case UploadStatus.EXPIRED: return 'badge-danger';
          case UploadStatus.EXPIRED_SOON: return 'badge-yellow';
        }
        break;

      case StatusTypes.MICROSITE_STATUS:
        switch(this.status) {
          case MicrositeStatus.ASSIGNED_INSPECTION: return 'badge-yellow';
          case MicrositeStatus.AWAIT_DOC_UPLOAD: return 'badge-blue';
          case MicrositeStatus.BA_INSPECTION: return 'badge-green';
          case MicrositeStatus.COI_ISSUED: return 'badge-green';
          case MicrositeStatus.COI_PROCESS: return 'badge-light-blue';
          case MicrositeStatus.COMPLETE_ESSENTIAL_DOC: return 'badge-green';
          case MicrositeStatus.INSPECTION_PROCESS: return 'badge-orange';
          case MicrositeStatus.NEED_DATA_REVISION: return 'badge-orange';
          case MicrositeStatus.NEED_DOCUMENT_REVISION: return 'badge-green';
        }
        break;

      case StatusTypes.DOCUMENT_STATUS:
        switch(this.status) {
          case DocumentStatus.COMPLETE: return 'badge-green';
          case DocumentStatus.NOT_COMPLETE: return 'badge-yellow';
        }
        break;
      case StatusTypes.COI_STATUS:
        switch(this.status) {
          case COIStatus.COMPLETE: return 'badge-green';
          case COIStatus.EXPIRED: return 'badge-red';
          case COIStatus.NOT_COMPLETE: return 'badge-yellow';
          case COIStatus.EXPIRED_SOON: return 'badge-purple';
        }
        break;
      case StatusTypes.INSPECTION_STATUS:
        switch(this.status) {
          case InspectionStatus.ASSIGNED_INSPECTION: return 'badge-green';
          case InspectionStatus.INSPECTION_COMPLETED: return 'badge-purple';
          case InspectionStatus.INSPECTION_PROCESS: return 'badge-orange';
          case InspectionStatus.UNASSIGNED: return 'badge-yellow';
        }
        break;
      case StatusTypes.BA_DOCUMENT_STATUS:
        switch(this.status) {
          case BaDocumentStatus.UPLOADED: return 'badge-green';
          case BaDocumentStatus.NOT_UPLOADED: return 'badge-yellow';
          case DocumentStatus.COMPLETE: return 'badge-green';
          case DocumentStatus.NOT_COMPLETE: return 'badge-yellow';
        }
        break;
      case StatusTypes.COI_STATUS:
        switch(this.status) {
          case COIStatus.COMPLETE: return 'badge-green';
          case COIStatus.NOT_COMPLETE: return 'badge-yellow';
          case COIStatus.EXPIRED: return 'badge-danger';
          case COIStatus.EXPIRED_SOON: return 'badge-orange';
        }
        break;
      case StatusTypes.SKHP_STATUS:
          switch(this.status) {
            case ExpiryStatus.VALID: return 'badge-green';
            case ExpiryStatus.ACTIVE: return 'badge-green';
            case ExpiryStatus.EXPIRED_SOON: return 'badge-orange';
            case ExpiryStatus.EXPIRED: return 'badge-danger';
          }
          break;
      case StatusTypes.OPERATION_STATUS:
        switch(this.status) {
          default: 'badge-info'
        }
        break;
    }
    return "";
  }

  get labelContent(): string {
    if (!this.status) return '-';
    return (this.statusType? `${this.statusType}.${this.status}` : this.status).toUpperCase();
  }
}
